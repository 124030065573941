import React, { useCallback, useEffect, useRef, useState } from 'react'
import './ModelConfigurator.scss'
import '@google/model-viewer'
import useFullscreen from '../../hooks/useFullscreen'
import ar_png from "../../images/ar.png"
import annotains_png from "../../images/annotains.png"
import { axiosPrivate } from '../../api/api'

const ModelConfigurator = ({
    model,
    usdz,
    bgColor,
    annotainsElements,
    setAnnotainsElements,
    setFeedbackFormStatus,
    onTabChange,
    orderId,
    annotainsText,
    status,
    variants
}) => {
    const modelViewer = useRef()
    const modelViewerWrapper = useRef()
    const [modelViewerWrapperCurrent, setModelViewerWrapperCurrent] = useState(null)
    const { isFullscreen, toggleFullscreen } = useFullscreen(modelViewerWrapperCurrent);
    const [annotains, setAnnotains] = useState(false)
    const [playAnimation, setPlayAnimation] = useState(false)
    const [variantName, setVariantName] = useState([])

    const [dimLines, setDimLines] = useState();
    const [toggle, setToggle] = useState(false)
    const [int, setInt] = useState(250)

    const drawLine = useCallback(
        (svgLine, dotHotspot1, dotHotspot2, dimensionHotspot) => {
            if (dotHotspot1 && dotHotspot2) {
                svgLine.setAttribute("x1", dotHotspot1.canvasPosition.x);
                svgLine.setAttribute("y1", dotHotspot1.canvasPosition.y);
                svgLine.setAttribute("x2", dotHotspot2.canvasPosition.x);
                svgLine.setAttribute("y2", dotHotspot2.canvasPosition.y);

                // use provided optional hotspot to tie visibility of this svg line to
                if (dimensionHotspot && !dimensionHotspot.facingCamera) {
                    svgLine.classList.add("hide");
                } else {
                    svgLine.classList.remove("hide");
                }
            }
        },
        []
    );

    const renderSVG = useCallback(() => {
        if (dimLines) {
            drawLine(
                dimLines[0],
                modelViewer.current.queryHotspot("hotspot-dot+X-Y+Z"),
                modelViewer.current.queryHotspot("hotspot-dot+X-Y-Z"),
                modelViewer.current.queryHotspot("hotspot-dim+X-Y")
            );
            drawLine(
                dimLines[1],
                modelViewer.current.queryHotspot("hotspot-dot+X-Y-Z"),
                modelViewer.current.queryHotspot("hotspot-dot+X+Y-Z"),
                modelViewer.current.queryHotspot("hotspot-dim+X-Z")
            );
            drawLine(
                dimLines[2],
                modelViewer.current.queryHotspot("hotspot-dot+X+Y-Z"),
                modelViewer.current.queryHotspot("hotspot-dot-X+Y-Z")
            ); // always visible
            drawLine(
                dimLines[3],
                modelViewer.current.queryHotspot("hotspot-dot-X+Y-Z"),
                modelViewer.current.queryHotspot("hotspot-dot-X-Y-Z"),
                modelViewer.current.queryHotspot("hotspot-dim-X-Z")
            );
            drawLine(
                dimLines[4],
                modelViewer.current.queryHotspot("hotspot-dot-X-Y-Z"),
                modelViewer.current.queryHotspot("hotspot-dot-X-Y+Z"),
                modelViewer.current.queryHotspot("hotspot-dim-X-Y")
            );
        }
    }, [dimLines, drawLine, modelViewer]);

    useEffect(() => {
        const dimLines = modelViewer.current.querySelectorAll("line");
        setDimLines(dimLines);
    }, []);

    useEffect(() => {
        modelViewer.current.addEventListener("camera-change", () => {
            if (dimLines) {
                renderSVG();
            }
        });
    }, [dimLines, renderSVG]);

    useEffect(() => {
        modelViewer.current.addEventListener("load", () => {
            if (modelViewer.current) {
                const center = modelViewer.current.getBoundingBoxCenter();
                const size = modelViewer.current.getDimensions();
                const x2 = size.x / 2;
                const y2 = size.y / 2;
                const z2 = size.z / 2;

                modelViewer.current.updateHotspot({
                    name: "hotspot-dot+X-Y+Z",
                    position: `${center.x + x2} ${center.y - y2} ${center.z + z2}`,
                });

                modelViewer.current.updateHotspot({
                    name: "hotspot-dim+X-Y",
                    position: `${center.x + x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`,
                });
                modelViewer.current.querySelector(
                    'button[slot="hotspot-dim+X-Y"]'
                ).textContent = `${(size.z * 100).toFixed(0)} cm`;

                modelViewer.current.updateHotspot({
                    name: "hotspot-dot+X-Y-Z",
                    position: `${center.x + x2} ${center.y - y2} ${center.z - z2}`,
                });

                modelViewer.current.updateHotspot({
                    name: "hotspot-dim+X-Z",
                    position: `${center.x + x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`,
                });
                modelViewer.current.querySelector(
                    'button[slot="hotspot-dim+X-Z"]'
                ).textContent = `${(size.y * 100).toFixed(0)} cm`;

                modelViewer.current.updateHotspot({
                    name: "hotspot-dot+X+Y-Z",
                    position: `${center.x + x2} ${center.y + y2} ${center.z - z2}`,
                });

                modelViewer.current.updateHotspot({
                    name: "hotspot-dim+Y-Z",
                    position: `${center.x} ${center.y + y2 * 1.1} ${center.z - z2 * 1.1}`,
                });
                modelViewer.current.querySelector(
                    'button[slot="hotspot-dim+Y-Z"]'
                ).textContent = `${(size.x * 100).toFixed(0)} cm`;

                modelViewer.current.updateHotspot({
                    name: "hotspot-dot-X+Y-Z",
                    position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`,
                });

                modelViewer.current.updateHotspot({
                    name: "hotspot-dim-X-Z",
                    position: `${center.x - x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`,
                });
                modelViewer.current.querySelector(
                    'button[slot="hotspot-dim-X-Z"]'
                ).textContent = `${(size.y * 100).toFixed(0)} cm`;

                modelViewer.current.updateHotspot({
                    name: "hotspot-dot-X-Y-Z",
                    position: `${center.x - x2} ${center.y - y2} ${center.z - z2}`,
                });

                modelViewer.current.updateHotspot({
                    name: "hotspot-dim-X-Y",
                    position: `${center.x - x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`,
                });
                modelViewer.current.querySelector(
                    'button[slot="hotspot-dim-X-Y"]'
                ).textContent = `${(size.z * 100).toFixed(0)} cm`;

                modelViewer.current.updateHotspot({
                    name: "hotspot-dot-X-Y+Z",
                    position: `${center.x - x2} ${center.y - y2} ${center.z + z2}`,
                });

                if (drawLine) {
                    renderSVG();
                }
            }
        });
    }, [drawLine, renderSVG]);

    useEffect(() => {
        setModelViewerWrapperCurrent(modelViewerWrapper.current)
    }, [])

    useEffect(() => {
        if (!playAnimation) {
            modelViewer.current.pause()
        }
    }, [playAnimation])

    // * Create Feedback
    const createFeedback = async (body) => {
        try {
            const result = await axiosPrivate.post(`/api/v1/orders/feedback/${orderId}/`, body)
            return result.data;
        } catch (error) {
            console.log(error);
        }
    }

    // * Annotations
    const annotationClicked = (annotation) => {
        const dataset = annotation.dataset;
        const targetPosition = dataset.position.split(" ").map(Number);

        modelViewer.current.cameraTarget = `${targetPosition[0]}m ${targetPosition[1]}m ${targetPosition[2]}m`;

        const azimuth = 90;
        const elevation = -90;

        const closerZoomRadius = 0.2;

        modelViewer.current.cameraOrbit = `${azimuth}deg ${elevation}deg ${closerZoomRadius}m`;
        modelViewer.current.fieldOfView = "45deg";
    };

    const handleAnnotation = useCallback((e) => {
        annotationClicked(e.target);
    }, []);

    const handleClick = event => {
        if (annotains) {
            const x = event.clientX;
            const y = event.clientY;

            const dataPosition = modelViewer?.current.positionAndNormalFromPoint(x, y);

            if (!dataPosition) {
                return;
            }

            const { position, normal } = dataPosition;

            createFeedback({
                "data_position": {
                    "position": position,
                    "normal": normal
                }
            })
                .then((data) => {
                    setAnnotainsElements(prevBtns => [
                        ...prevBtns,
                        {
                            element: <button
                                key={data.id}
                                className="hotspot"
                                data-position={position}
                                data-normal={normal}
                                slot={`hotspot-${data.id}`}
                                onClick={handleAnnotation}
                            ></button>,
                            id: data.id
                        }
                    ])
                })

            setAnnotains(false)
            setFeedbackFormStatus(true)
            onTabChange(1)
        }
    };

    useEffect(() => {
        const data = annotainsText?.map(item => {
            return {
                element: <button
                    key={item.id}
                    className="hotspot"
                    onClick={handleAnnotation}
                    data-position={`${item.data_position.position.x} ${item.data_position.position.y} ${item.data_position.position.z}`}
                    data-normal={`${item.data_position.normal.x} ${item.data_position.normal.y} ${item.data_position.normal.z}`}
                    slot={`hotspot-${item.id}`}
                ></button>,
                id: item.id
            }
        });

        setAnnotainsElements(data)
    }, [annotainsText, setAnnotainsElements, handleAnnotation])

    useEffect(() => {
        const newVariant = variants?.map(item => {
            const activeOption = item.options.find((opt) => {
                if (opt.is_active) {
                    return opt.name
                } return false
            })

            return {
                name: item.name,
                option: activeOption?.name
            }
        })

        setVariantName(newVariant)
    }, [variants])

    const handleVariant = (option, name) => {
        const newVariant = variantName?.map(item => {
            if (item.name === name) {
                return {
                    name: item.name,
                    option,
                }
            } else return item
        })

        setVariantName(newVariant)
    }

    useEffect(() => {
        modelViewer.current.variantName = variantName?.map(item => `${item.name}:${item.option}`).join('_');
    }, [variantName])

    useEffect(() => {
        function interpolationModel(m) {
            const orbitCycle = [m.cameraOrbit];

            const timeoutId = setTimeout(() => {
                m.cameraOrbit =
                    orbitCycle[
                    ("45deg 0deg 2.5m", "90deg 0deg 2.5m", "135deg 0deg 2.5m")
                    ];
            }, 500);

            return () => {
                clearTimeout(timeoutId);
            };
        }

        interpolationModel(modelViewer.current);

        setTimeout(() => {
            setInt(150)
        }, 1500);
    }, []);

    console.log(model);

    return (
        <div ref={modelViewerWrapper} className='model-configurator-wrapper' style={{ backgroundColor: bgColor }}>
            <model-viewer
                onClick={handleClick}
                ref={modelViewer}
                src={model}
                {...(usdz && { "ios-src": usdz })}
                touch-action="pan-y"
                alt="A 3D model carousel"
                ar
                loading="eager"
                ar-scale="fixed"
                shadow-intensity="1"
                disable-tap
                interpolation-decay={int}
                camera-orbit="125deg 55deg 4m"
                max-camera-orbit="auto 100deg auto"
                {...(!annotains && { "camera-controls": "camera-controls" })}
                {...(playAnimation && { "autoplay": "autoplay" })}
            >
                <button
                    slot="hotspot-dot+X-Y+Z"
                    className={`dot ${!toggle ? "hide" : null}`}
                    data-position="1 -1 1"
                    data-normal="1 0 0"
                ></button>
                <button
                    slot="hotspot-dim+X-Y"
                    className={`dim ${!toggle ? "hide" : null}`}
                    data-position="1 -1 0"
                    data-normal="1 0 0"
                ></button>
                <button
                    slot="hotspot-dot+X-Y-Z"
                    className={`dot ${!toggle ? "hide" : null}`}
                    data-position="1 -1 -1"
                    data-normal="1 0 0"
                ></button>
                <button
                    slot="hotspot-dim+X-Z"
                    className={`dim ${!toggle ? "hide" : null}`}
                    data-position="1 0 -1"
                    data-normal="1 0 0"
                ></button>
                <button
                    slot="hotspot-dot+X+Y-Z"
                    className={`dot ${!toggle ? "hide" : null}`}
                    data-position="1 1 -1"
                    data-normal="0 1 0"
                ></button>
                <button
                    slot="hotspot-dim+Y-Z"
                    className={`dim ${!toggle ? "hide" : null}`}
                    data-position="0 -1 -1"
                    data-normal="0 1 0"
                ></button>
                <button
                    slot="hotspot-dot-X+Y-Z"
                    className={`dot ${!toggle ? "hide" : null}`}
                    data-position="-1 1 -1"
                    data-normal="0 1 0"
                ></button>
                <button
                    slot="hotspot-dim-X-Z"
                    className={`dim ${!toggle ? "hide" : null}`}
                    data-position="-1 0 -1"
                    data-normal="-1 0 0"
                ></button>
                <button
                    slot="hotspot-dot-X-Y-Z"
                    className={`dot ${!toggle ? "hide" : null}`}
                    data-position="-1 -1 -1"
                    data-normal="-1 0 0"
                ></button>
                <button
                    slot="hotspot-dim-X-Y"
                    className={`dim ${!toggle ? "hide" : null}`}
                    data-position="-1 -1 0"
                    data-normal="-1 0 0"
                ></button>
                <button
                    slot="hotspot-dot-X-Y+Z"
                    className={`dot ${!toggle ? "hide" : null}`}
                    data-position="-1 -1 1"
                    data-normal="-1 0 0"
                ></button>
                <svg
                    id="dimLines"
                    width="100%"
                    height="100%"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`dimensionLineContainer ${!toggle ? "hide" : null}`}
                >
                    <line className="dimensionLine"></line>
                    <line className="dimensionLine"></line>
                    <line className="dimensionLine"></line>
                    <line className="dimensionLine"></line>
                    <line className="dimensionLine"></line>
                </svg>

                {
                    annotainsElements?.map(item => item.element)
                }

            </model-viewer>

            {
                variants?.length > 0 && <div className="variants-ui">
                    {variants?.map(variant =>
                        <div key={variant.name} className="item">
                            <p>{variant.name}</p>
                            <div className="select-wrapper">
                                <select
                                    id={variant.name}
                                    onChange={(e) => { handleVariant(e.target.value, variant.name) }}
                                >
                                    {variant.options.map(option => {
                                        if (option.is_active) {
                                            return <option key={option.id} value={option.name} >{option.name}</option>
                                        } else return false
                                    })}
                                </select>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 6L8 11L3 6" stroke="#555E6D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>)}
                </div>
            }

            <div className='configurator-left-side'>
                <button onClick={toggleFullscreen} className='configurator-btn'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M22.75 17.5L22.75 22.75L17.5 22.75" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16.625 16.625L22.75 22.75" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.25 10.5L5.25 5.25L10.5 5.25" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.375 11.375L5.25 5.25" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <button className='configurator-btn'>
                    <img src={ar_png} alt="ar" />
                </button>
                {

                }
                <button className='configurator-btn'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <path d="M13.8333 12.6667V15.4167M8.33333 15.4167V12.6667H5.58333M8.33333 7.16667H5.58333M1 2.21667V19.45C1 19.5959 1.05795 19.7358 1.16109 19.8389C1.26424 19.9421 1.40413 20 1.55 20H18.7833C18.9292 20 19.0691 19.9421 19.1722 19.8389C19.2754 19.7358 19.3333 19.5959 19.3333 19.45V13.2167C19.3333 13.0708 19.2754 12.9309 19.1722 12.8278C19.0691 12.7246 18.9292 12.6667 18.7833 12.6667H8.88333C8.73746 12.6667 8.59757 12.6087 8.49442 12.5056C8.39128 12.4024 8.33333 12.2625 8.33333 12.1167V2.21667C8.33333 2.14444 8.31911 2.07292 8.29147 2.00619C8.26383 1.93946 8.22331 1.87883 8.17224 1.82776C8.12117 1.77668 8.06054 1.73617 7.99381 1.70853C7.92708 1.68089 7.85556 1.66667 7.78333 1.66667H1.55C1.47777 1.66667 1.40625 1.68089 1.33952 1.70853C1.2728 1.73617 1.21216 1.77668 1.16109 1.82776C1.11002 1.87883 1.06951 1.93946 1.04187 2.00619C1.01423 2.07292 1 2.14444 1 2.21667Z" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>

            {
                <div className='configurator-left-side'>
                    <button className={`configurator-btn ${toggle ? 'active' : null}`} onClick={() => { setToggle(!toggle) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path d="M13.8333 12.6667V15.4167M8.33333 15.4167V12.6667H5.58333M8.33333 7.16667H5.58333M1 2.21667V19.45C1 19.5959 1.05795 19.7358 1.16109 19.8389C1.26424 19.9421 1.40413 20 1.55 20H18.7833C18.9292 20 19.0691 19.9421 19.1722 19.8389C19.2754 19.7358 19.3333 19.5959 19.3333 19.45V13.2167C19.3333 13.0708 19.2754 12.9309 19.1722 12.8278C19.0691 12.7246 18.9292 12.6667 18.7833 12.6667H8.88333C8.73746 12.6667 8.59757 12.6087 8.49442 12.5056C8.39128 12.4024 8.33333 12.2625 8.33333 12.1167V2.21667C8.33333 2.14444 8.31911 2.07292 8.29147 2.00619C8.26383 1.93946 8.22331 1.87883 8.17224 1.82776C8.12117 1.77668 8.06054 1.73617 7.99381 1.70853C7.92708 1.68089 7.85556 1.66667 7.78333 1.66667H1.55C1.47777 1.66667 1.40625 1.68089 1.33952 1.70853C1.2728 1.73617 1.21216 1.77668 1.16109 1.82776C1.11002 1.87883 1.06951 1.93946 1.04187 2.00619C1.01423 2.07292 1 2.14444 1 2.21667Z" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    {
                        status !== 'revision' && <button onClick={() => { setAnnotains(!annotains) }} className={`${annotains ? 'active' : ''} configurator-btn`}>
                            <img src={annotains_png} alt="annotains" />
                        </button>
                    }
                    {
                        <button onClick={() => { setPlayAnimation(!playAnimation) }} className={`configurator-btn ${playAnimation ? 'active' : null}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z" stroke="#7E899B" strokeWidth="2" strokeMiterlimit="10" />
                                <path d="M17.5 14L12.25 10.5V17.5L17.5 14Z" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    }
                    <button onClick={toggleFullscreen} className={`configurator-btn ${isFullscreen ? 'active' : null}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path d="M22.75 17.5L22.75 22.75L17.5 22.75" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16.625 16.625L22.75 22.75" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.25 10.5L5.25 5.25L10.5 5.25" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.375 11.375L5.25 5.25" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
            }
        </div >
    )
}

export default ModelConfigurator