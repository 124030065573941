import React, { useEffect, useRef, useState } from 'react'
import { Box, Modal, Pagination, Stack } from '@mui/material'
import { Link, useSearchParams } from 'react-router-dom'
import SideMenu from '../../components/SideMenu/SideMenu'
import { axiosPrivate } from '../../api/api'
import OrderProductSkeleton from '../../components/OrderProductSkeleton/OrderProductSkeleton'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import './MyProducts.scss'
import { FacebookShareButton, WhatsappShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton } from "react-share";
import { FacebookIcon, WhatsappIcon, LinkedinIcon, TwitterIcon, EmailIcon } from "react-share";
import { ToastContainer, toast } from 'react-toastify'
import SearchResultText from '../../components/SearchResultText/SearchResultText'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: "fit-content",
    bgcolor: 'background.paper',
    borderRadius: '6px',
    boxShadow: 24,
    p: 3,
};

const demoModel = [
    {
        "id": "demo-model-1",
        "name": "Hottub Dream",
        "sku": "32984jd",
        "image": "https://dev.api.imajin.ai/media/model_images/poster_19.png",
        "file": "https://dev.api.imajin.ai/media/ar_models/dre2600-2cl_1.glb",
        "iframe": "<iframe src=https://dev.app.imajin.ai/model/viewer/6782fe17-1d55-49d9-8552-d2de0553bb2d style=\"overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100vh;width:100%;z-index: 1;position: relative;\" frameborder=\"0\" allow=\"fullscreen\"></iframe>",
        "delivered_time": "2024-01-25",
    },
    {
        "id": "demo-model-2",
        "name": "Delonghi La Specialista",
        "sku": "i24bd83",
        "image": "https://dev.api.imajin.ai/media/model_images/poster_17.png",
        "file": "https://dev.api.imajin.ai/media/ar_models/coffee3.glb",
        "iframe": "<iframe src=https://dev.app.imajin.ai/model/viewer/aef689ee-6e94-4a62-8c31-5b84a65eab90 style=\"overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100vh;width:100%;z-index: 1;position: relative;\" frameborder=\"0\" allow=\"fullscreen\"></iframe>",
        "delivered_time": "2024-01-25",
    },
    {
        "id": "demo-model-3",
        "name": "Frost X",
        "sku": "904308090909",
        "image": "https://dev.api.imajin.ai/media/ar_models/ea513565-f95f-49f5-9d9e-67886b36dc6f/image/poster_12.png",
        "file": "https://dev.api.imajin.ai/media/ar_models/ea513565-f95f-49f5-9d9e-67886b36dc6f/file/Frost_2.glb",
        "iframe": "<iframe src=https://dev.app.imajin.ai/model/viewer/ea513565-f95f-49f5-9d9e-67886b36dc6f style=\"overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100vh;width:100%;z-index: 1;position: relative;\" frameborder=\"0\" allow=\"fullscreen\"></iframe>",
        "delivered_time": "2024-01-25",
    }
]

const MyProducts = () => {
    const [products, setProducts] = useState(null)
    const [loading, setLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [pageCount, setPageCount] = useState(null)
    const [queryParams, setQueryParams] = useState({
        q: '',
        order: '-delivered_time',
        page: '1',
        page_size: '5'
    })

    // * Get query params initial value from URL
    useEffect(() => {
        const keys = ["q", "order", "page", "page_size"];
        const newState = { ...queryParams }

        keys.forEach(key => {
            const value = searchParams.get(key);
            if (value || value === '') {
                newState[key] = value
            }
        });

        setQueryParams(newState)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    //  * Fetch products data
    useEffect(() => {
        const getProducts = async () => {
            setLoading(true);
            try {
                const result = await axiosPrivate.get(`/api/v1/models/?q=${queryParams.q}&order=${queryParams.order}&page=${queryParams.page}&page_size=${queryParams.page_size}`);
                setProducts(result.data)
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };
        getProducts()
    }, [queryParams.order, queryParams.page, queryParams.page_size, queryParams.q]);

    // * Change query params function
    const changeQueryParams = (name, data) => {
        searchParams.set(name, data)
        setSearchParams(searchParams)

        if (name !== "page") {
            changeQueryParams("page", 1)
        }
    }

    // * Handle delivery time
    const handleDeliveryTime = (order) => {
        changeQueryParams('order', order === '-delivered_time' ? 'delivered_time' : '-delivered_time')
    }

    // * Pagination
    useEffect(() => {
        setPageCount(Math.ceil(parseInt(products?.count) / parseInt(queryParams.page_size)))
    }, [products?.count, queryParams.page_size])

    // * Actions
    const [activeDiv, setActiveDiv] = useState(null);

    const handleActionBtn = (e, id) => {
        e.preventDefault()
        setActiveDiv(id === activeDiv ? null : id);
    };

    // * Share links
    const inputValue = useRef();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const url = `${window.location.origin}/model/viewer/${activeDiv}`;
    const notify = (message) => toast(message);

    const handleCopy = () => {
        navigator.clipboard.writeText(inputValue.current.value);
        notify('Link copied to clipboard.')
    };

    const handleCopyEmbed = (text) => {
        navigator.clipboard.writeText(text);
        notify('Link copied to clipboard.')
    };

    const handleDownload = (model) => {
        window.location.href = model;
    }

    return (
        <>
            <ToastContainer autoClose={1000} hideProgressBar={true} />
            <SideMenu />
            <div className='orders-wrapper'>
                <div className='head'>
                    <h3>My products</h3>
                    <p className='d-d-none mobile-desc'>A 3D model of your product is prepared.</p>
                    <div className='title'>
                        <p className='d-m-none'>In this page, you can view your ready products in detail and perform any actions on them.</p>
                        <div className="d-d-none"></div>
                        <div className='search-wrapper'>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.51562 16.4062C13.3212 16.4062 16.4062 13.3212 16.4062 9.51562C16.4062 5.71004 13.3212 2.625 9.51562 2.625C5.71004 2.625 2.625 5.71004 2.625 9.51562C2.625 13.3212 5.71004 16.4062 9.51562 16.4062Z" stroke="#A1A8B4" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round" />
                                <path d="M14.3882 14.3867L18.3749 18.3734" stroke="#A1A8B4" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round" />
                            </svg>
                            <input
                                placeholder='Search for In My prod...'
                                type="text"
                                onChange={(e) => { changeQueryParams('q', e.target.value) }}
                                value={queryParams.q}
                            />
                        </div>
                    </div>
                </div>

                <div className="search-filter-wrapper">
                    <div></div>
                    {queryParams.q && <SearchResultText count={products?.count} text={queryParams.q} />}
                </div>

                <div className='table-wrapper'>
                    <div className='table-head'>
                        <ul>
                            <li>Product details</li>
                            <li className='d-m-none'>SKU</li>
                            <li className='d-m-none'>
                                Delivered time
                                <button onClick={() => { handleDeliveryTime(queryParams.order) }}>
                                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.75 7.5L6 3.75L2.25 7.5" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.75 14.5L6 18.25L2.25 14.5" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </li>
                            <li className='my-products-action-head'>Action</li>
                        </ul>
                    </div>
                    <div className='table-body'>
                        {
                            loading
                                ? [1, 2, 3, 4, 5].map((skeleton, index) => <div className='order-product-skeleton' key={index}><OrderProductSkeleton /></div>)
                                : products?.results.length === 0
                                    ? demoModel.map(item => (
                                        <div className='list-item' key={item.id}>
                                            <Link to={`/my-products/${item.id}`} className='title'>
                                                <div className='image'>
                                                    <img src={item.image} alt="product" />
                                                </div>
                                                <h3 className='name'>{item.name}</h3>
                                            </Link>
                                            <p className='sku d-m-none'>{item.sku}</p>
                                            <p className='delivery-time d-m-none'>{item.delivered_time}</p>
                                            <div className='my-products-action-btns'>
                                                <button
                                                    className='btn-action'
                                                    onClick={(e) => { handleActionBtn(e, item.id) }} >
                                                    <div className='dot'></div>
                                                    <div className='dot'></div>
                                                    <div className='dot'></div>
                                                </button>
                                                <ul
                                                    className={`${item.id === activeDiv ? 'active-dropdown' : ''}`}
                                                >
                                                    <li>
                                                        <CodeRoundedIcon />
                                                        <button className='action-btn' onClick={() => { handleCopyEmbed(item.iframe) }}>Embed</button>
                                                    </li>
                                                    <li>
                                                        <ReplyRoundedIcon />
                                                        <button className='action-btn' type='button' onClick={(e) => { e.preventDefault(); handleOpen() }}>Share</button>
                                                    </li>
                                                    <li>
                                                        <GetAppRoundedIcon />
                                                        <button className='action-btn' onClick={() => { handleDownload(item.file) }} >Download</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ))
                                    : products?.results.map(item => (
                                        <div className='list-item' key={item.id}>
                                            <Link to={`/my-products/${item.id}`} className='title'>
                                                <div className='image'>
                                                    <img src={item.image} alt="product" />
                                                </div>
                                                <h3 className='name'>{item.name}</h3>
                                            </Link>
                                            <p className='sku d-m-none'>{item.sku}</p>
                                            <p className='delivery-time d-m-none'>{item.delivered_time}</p>
                                            <div className='my-products-action-btns'>
                                                <button
                                                    className='btn-action'
                                                    onClick={(e) => { handleActionBtn(e, item.id) }} >
                                                    <div className='dot'></div>
                                                    <div className='dot'></div>
                                                    <div className='dot'></div>
                                                </button>
                                                <ul
                                                    className={`${item.id === activeDiv ? 'active-dropdown' : ''}`}
                                                >
                                                    <li>
                                                        <CodeRoundedIcon />
                                                        <button className='action-btn' onClick={() => { handleCopyEmbed(item.iframe) }}>Embed</button>
                                                    </li>
                                                    <li>
                                                        <ReplyRoundedIcon />
                                                        <button className='action-btn' type='button' onClick={(e) => { e.preventDefault(); handleOpen() }}>Share</button>
                                                    </li>
                                                    <li>
                                                        <GetAppRoundedIcon />
                                                        <button className='action-btn' onClick={() => { handleDownload(item.file) }} >Download</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ))
                        }
                    </div>
                    <div className='table-pagination'>
                        <div className='rows-per-page'>
                            <p>Rows per page</p>
                            <select onChange={(e) => { changeQueryParams('page_size', e.target.value) }}>
                                {
                                    [5, 10, 25, 50].map(option =>
                                        <option
                                            key={option}
                                            value={option || 5}
                                            {...(parseInt(queryParams.page_size) === option && { "selected": true })}
                                        >
                                            {option}
                                        </option>)
                                }
                            </select>
                        </div>
                        <div className='pagination'>
                            <Stack spacing={2}>
                                <Pagination
                                    count={pageCount || 1}
                                    page={parseInt(queryParams.page)}
                                    onChange={(e, value) => { changeQueryParams('page', value) }}
                                />
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='share-link-wrapper'>
                        <h3 className='title'>Share model on:</h3>
                        <div className='social-media-btns'>
                            <div className='item'>
                                <FacebookShareButton url={url}>
                                    <FacebookIcon size={54} round={true} />
                                </FacebookShareButton>
                                <span>Facebook</span>
                            </div>
                            <div className='item'>
                                <WhatsappShareButton url={url}>
                                    <WhatsappIcon size={54} round={true} />
                                </WhatsappShareButton>
                                <span>WhatsApp</span>
                            </div>
                            <div className='item'>
                                <LinkedinShareButton url={url}>
                                    <LinkedinIcon size={54} round={true} />
                                </LinkedinShareButton>
                                <span>Linkedin</span>
                            </div>
                            <div className='item'>
                                <TwitterShareButton url={url}>
                                    <TwitterIcon size={54} round={true} />
                                </TwitterShareButton>
                                <span>Twitter</span>
                            </div>
                            <div className='item'>
                                <EmailShareButton url={url}>
                                    <EmailIcon size={54} round={true} />
                                </EmailShareButton>
                                <span>Email</span>
                            </div>
                        </div>
                        <div className='copy-link'>
                            <input ref={inputValue} value={url} readOnly />
                            <button onClick={handleCopy}>copy</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default MyProducts