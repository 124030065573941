import React from 'react'
import './Request.scss'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import RequestImageUpload from '../RequestImageUpload/RequestImageUpload';
import { useDispatch } from 'react-redux';
import { deleteProduct } from '../../redux/reducers/requestReducer';
import { updateSingleProduct } from '../../redux/actions/request';
// import WithAi from '../WithAi/WithAi';

const Request = ({ id, product, productsID, setProductsID, register, errors, setError, clearErrors, isSubmitted, ai, setAi, setImageLength }) => {
    const dispatch = useDispatch()

    const handleDeleteProduct = id => {
        dispatch(deleteProduct(id))
        setProductsID(productsID.filter(item => Number(item) !== id).map(Number))
        clearErrors(`file-${id}`)
    }

    return (
        <div className="request-wrapper">
            <div className="header">
                {product.status === "need_data" ? <div className='warning-message'>
                    <div className='warning_svg'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path d="M10.5 18.375C14.8492 18.375 18.375 14.8492 18.375 10.5C18.375 6.15076 14.8492 2.625 10.5 2.625C6.15076 2.625 2.625 6.15076 2.625 10.5C2.625 14.8492 6.15076 18.375 10.5 18.375Z" stroke="#F79537" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.84375 9.84375H10.5V14.4375H11.1562" stroke="#F79537" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.3359 7.875C10.8796 7.875 11.3203 7.43428 11.3203 6.89062C11.3203 6.34697 10.8796 5.90625 10.3359 5.90625C9.79228 5.90625 9.35156 6.34697 9.35156 6.89062C9.35156 7.43428 9.79228 7.875 10.3359 7.875Z" fill="#F79537" />
                        </svg>
                    </div>
                    <p>{product.warning_message}</p>
                </div> : null}
                {productsID.length > 1 && <DeleteOutlineRoundedIcon onClick={() => { handleDeleteProduct(product.id) }} />}
            </div>
            <RequestImageUpload
                id={product.id}
                images={product.images}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                isSubmitted={isSubmitted}
                ai={ai}
                setImageLength={setImageLength}
            />
            <div className="product-information-wrapper">
                <div className="child">
                    <div className='child-head'>
                        <p className="title">Product name</p>
                    </div>
                    <div className="input-group">
                        <input
                            {...register(`name-${id}`, { required: true })}
                            type="text"
                            placeholder='Red Automatic Coffee Maker'
                            defaultValue={product.name}
                            onBlur={(e) => { e.target.value.length > 0 && dispatch(updateSingleProduct({ id: product.id, field: "name", data: e.target.value })) }}
                        />
                        <p className="error-message-request">{errors[`name-${id}`] && 'Please fill out this field'}</p>
                    </div>
                </div>
                <div className="child">
                    <div className='child-head'>
                        <p className="title">Product ID/SKU</p>
                    </div>
                    <div className="input-group">
                        <input
                            type="text"
                            placeholder='5WA560'
                            defaultValue={product.sku}
                            onBlur={(e) => { e.target.value.length > 0 && dispatch(updateSingleProduct({ id: product.id, field: "sku", data: e.target.value })) }}
                        />
                        <p className="error-message-request"></p>
                    </div>
                </div>
                <div className="child">
                    <div className='child-head'>
                        <p className="title">Dimensions</p>
                        <select
                            onChange={(e) => { dispatch(updateSingleProduct({ id: product.id, field: "unit", data: e.target.value })) }}
                            defaultValue={product.unit}
                            className="unit-select"
                        >
                            <option value="cm">cm</option>
                            <option value="in">in</option>
                        </select>
                    </div>
                    <div className="input-group dimensions-group-wrapper">
                        <div className="dimensions-group">
                            <input
                                {...register(`length-${id}`, {
                                    required: true,
                                    maxLength: 4,
                                    pattern: /^[0-9]*$/,
                                })}
                                type="text"
                                placeholder='L'
                                defaultValue={product.length}
                                onBlur={(e) => { e.target.value.length > 0 && dispatch(updateSingleProduct({ id: product.id, field: "length", data: e.target.value })) }}
                            />
                            <p className="error-message-request">
                                {errors[`length-${id}`]?.type === "required" && 'Please fill out this field'}
                                {errors[`length-${id}`]?.type === "maxLength" && 'Maximum length exceeded'}
                                {errors[`length-${id}`]?.type === "pattern" && 'Numbers-Only Input Required'}
                            </p>
                        </div>
                        <div className="dimensions-group">
                            <input
                                {...register(`width-${id}`, {
                                    required: true,
                                    maxLength: 4,
                                    pattern: /^[0-9]*$/,
                                })}
                                type="text"
                                placeholder='W'
                                defaultValue={product.width}
                                onBlur={(e) => { e.target.value.length > 0 && dispatch(updateSingleProduct({ id: product.id, field: "width", data: e.target.value })) }}
                            />
                            <p className="error-message-request">
                                {errors[`width-${id}`]?.type === "required" && 'Please fill out this field'}
                                {errors[`width-${id}`]?.type === "maxLength" && 'Maximum length exceeded'}
                                {errors[`width-${id}`]?.type === "pattern" && 'Numbers-Only Input Required'}
                            </p>
                        </div>
                        <div className="dimensions-group">
                            <input
                                {...register(`height-${id}`, {
                                    required: true,
                                    maxLength: 4,
                                    pattern: /^[0-9]*$/,
                                })}
                                type="text"
                                placeholder='H'
                                defaultValue={product.height}
                                onBlur={(e) => { e.target.value.length > 0 && dispatch(updateSingleProduct({ id: product.id, field: "height", data: e.target.value })) }}
                            />
                            <p className="error-message-request">
                                {errors[`height-${id}`]?.type === "required" && 'Please fill out this field'}
                                {errors[`height-${id}`]?.type === "maxLength" && 'Maximum length exceeded'}
                                {errors[`height-${id}`]?.type === "pattern" && 'Numbers-Only Input Required'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="child">
                <div className='child-head'>
                    <p className="title">Additional info (urls, animation, variations and etc.)</p>
                </div>
                <div className="input-group">
                    <textarea
                        rows="10"
                        type="text"
                        defaultValue={product.description}
                        onBlur={(e) => { e.target.value.length > 0 && dispatch(updateSingleProduct({ id: product.id, field: "description", data: e.target.value })) }}
                    ></textarea>
                    <p className="error-message-request"></p>
                </div>
            </div>
        </div>
    )
}

export default Request