import React, { useState } from 'react'

const AnnotationButton = ({ id, position, normal, text, model }) => {
    const [show, setShow] = useState(false)

    const annotationClicked = (annotation) => {
        const dataset = annotation.dataset;
        const targetPosition = dataset.position.split(" ").map(Number);

        model.current.cameraTarget = `${targetPosition[0]}m ${targetPosition[1]}m ${targetPosition[2]}m`;

        const azimuth = 90;
        const elevation = -90;

        const closerZoomRadius = 0.2;

        model.current.cameraOrbit = `${azimuth}deg ${elevation}deg ${closerZoomRadius}m`;
        model.current.fieldOfView = "45deg";
    };

    const handleButton = (e) => {
        annotationClicked(e.target);
        setShow(!show)
    };

    return (
        <button
            className="hotspot"
            id="salam"
            data-position={position}
            data-normal={normal}
            slot={`hotspot-${id}`}
            onClick={handleButton}
        >
            {
                show && <div className='annotations-show'>
                    <p>{text}</p>
                </div>
            }
        </button>
    )
}

export default AnnotationButton