import React, { useState, useRef } from 'react'
import './ModelConfiguratorEditor.scss'
import Switch from '../Switch/Switch'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import DoNotDisturbRoundedIcon from '@mui/icons-material/DoNotDisturbRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import { Link, useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDispatch, useSelector } from 'react-redux';
import {
    addNew,
    showInput,
    updateAnnotationText,
    deleteAnnotationLast,
    updateVariant,
    updateEnviroment
} from '../../redux/reducers/modelViewerReducer';
import AnnotationListItem from '../AnnotationListItem/AnnotationListItem';
import ColorPicker from '../ColorPicker/ColorPicker';
import sunny from '../../images/sunny.jpeg'
import night from '../../images/night.jpeg'
import indoor from '../../images/indoor.jpeg'
import outdoor from '../../images/outdoor.jpeg'

const staticEnvironment = [
    {
        name: 'None',
        slug: 'none',
        image: null
    },
    {
        name: 'Sunny',
        slug: 'sunny',
        image: sunny
    },
    {
        name: 'Night',
        slug: 'night',
        image: night
    },
    {
        name: 'İndoor',
        slug: 'indoor',
        image: indoor
    },
    {
        name: 'Outdoor',
        slug: 'outdoor',
        image: outdoor
    },
]

const ModelConfiguratorEditor = () => {
    const { data, modelAnnations } = useSelector((state) => state.modelViewer);
    const dispatch = useDispatch()
    const [text, setText] = useState('')
    const [annotainError, setAnnotationError] = useState('')
    const annotainForm = useRef()
    const product_id = useParams().id

    // * Handle Annotations Input
    const handleAnnotationText = e => {
        const text = e.target.value;
        if (text.length === 100) {
            setAnnotationError('Annotation text must be less than 100 characters.')
        } else {
            setText(e.target.value)
            setAnnotationError('')
        }
    }

    // * Handle Submit Form Data
    const handleSubmitForm = (e) => {
        e.preventDefault()
        if (text.length > 0) {
            dispatch(updateAnnotationText({
                id: data.annotations[data.annotations.length - 1].id,
                text
            }))
            setText('')
            dispatch(showInput(false))
            setAnnotationError('')
        }
    }

    const handleClickOutside = (event) => {
        if (annotainForm.current && !annotainForm.current.contains(event.target)) {
            dispatch(showInput(false))
            document.removeEventListener("click", handleClickOutside, true)
            dispatch(deleteAnnotationLast())
        }
    }

    document.addEventListener("click", handleClickOutside, true)

    // * Handle variant option
    const handleVariantChecked = (name, id, status) => {
        dispatch(updateVariant({ name, id, status }))
    }

    // * Find active options
    const countActiveOptions = (options) => {
        return options.reduce((count, option) => {
            if (option.is_active) {
                return count + 1;
            } else {
                return count;
            }
        }, 0);
    }

    return (
        <div className='model-configurator-editor-wrapper'>
            <div className='model-configurator-editor'>
                <h3 className='title'>Actions</h3>
                <ul>
                    <li>
                        <p>Enable autoload</p>
                        <Switch name="autoLoad" />
                    </li>
                    <li>
                        <p>Autorotate</p>
                        <Switch name="autoRotate" />
                    </li>
                </ul>

                <div className='annotations-wrapper'>
                    <Accordion className="accardion-wrapper">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className='editor-tab-head'
                        >
                            <p>Annotations</p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='annotations'>
                                <div className="annotain-list-wrapper">
                                    {
                                        !['demo-model-1', "demo-model-2", "demo-model-3"].includes(product_id) && <button
                                            className="add-new"
                                            onClick={() => { dispatch(addNew(true)); setAnnotationError('') }}
                                        >
                                            <AddRoundedIcon />
                                            <span>New</span>
                                        </button>
                                    }

                                    {
                                        modelAnnations.showInput && <>
                                            <form
                                                ref={annotainForm}
                                                className='text-wrapper'
                                                onSubmit={(e) => { handleSubmitForm(e) }}
                                            >
                                                <input
                                                    onChange={(e) => { handleAnnotationText(e) }}
                                                    type="text"
                                                    placeholder='Please type your annotation'
                                                    autoFocus
                                                    maxLength="100"
                                                />
                                                <button className='send'>
                                                    <ArrowUpwardRoundedIcon />
                                                </button>
                                            </form>
                                            <p className="error-text">{annotainError}</p>
                                        </>
                                    }

                                    <div className="annotain-list">
                                        {
                                            data?.annotations?.map(item => {
                                                if (item.text) {
                                                    return (
                                                        <AnnotationListItem
                                                            key={item.id}
                                                            id={item.id}
                                                            text={item.text}
                                                        />
                                                    )
                                                } return false
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <h3 className='title'>Scene</h3>
                <div className='change-background-color'>
                    <p>Change background color</p>
                    <ColorPicker />
                </div>

                <div className='select-environment-wrapper'>
                    <Accordion className="accardion-wrapper">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className='editor-tab-head'
                        >
                            <p>Select environment </p>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className='select-environment'>
                                {
                                    staticEnvironment.map((item, index) => <div key={index} className={`item ${data?.environment === item.slug ? 'active' : null}`} onClick={() => { dispatch(updateEnviroment({ environment: item.slug, id: data?.id })) }}>
                                        <div className='image'>
                                            {item.image ? <img src={item.image} alt='' /> : <DoNotDisturbRoundedIcon />}
                                        </div>
                                        <p>{item.name}</p>
                                    </div>)
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className='enable-variants-wrapper'>
                    <Accordion className="accardion-wrapper">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            className='editor-tab-head'
                        >
                            <p>Enable variants </p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='variant-parent'>
                                {
                                    data?.variants?.length > 0
                                        ? data?.variants?.map(variant =>
                                            <div key={variant.name} className="item">
                                                <h3>{variant.name}</h3>
                                                <div className="options">
                                                    {
                                                        variant.options.map(option =>
                                                            <div key={option.id} className='option'>
                                                                <p>{option.name}</p>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => { handleVariantChecked(variant.name, option.id, e.target.checked,) }}
                                                                    defaultChecked={option.is_active}
                                                                    disabled={countActiveOptions(variant.options) === 1 && option.is_active ? true : false}
                                                                />
                                                            </div>)
                                                    }
                                                </div>
                                            </div>)
                                        : <p className='empty-variants'>There are no variants for this product</p>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className='learn-more-customization'>
                    <Link to="/">
                        <OpenInNewIcon />
                        <span>Learn more about customization</span>
                    </Link>
                </div>
            </div>
        </div >
    )
}

export default ModelConfiguratorEditor