import React, { useState } from 'react'
import "./ModelDetails.scss"
import useTabs from '../../hooks/useTabs'
import { Box, Modal } from '@mui/material'
import { axiosPrivate } from '../../api/api'
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

const modelDetailsTabs = [
    {
        name: "Analytics",
        slug: "analytics"
    },
    {
        name: "3D Ads",
        slug: "ads"
    },
    {
        name: "Details",
        slug: "details"
    },

]

const ModelDetailsReady = ({ iframe, productLink, download_url, views, live_views, engagement_time, ar_button_click }) => {
    const product_id = useParams().id
    const { activeTab, onTabChange } = useTabs(['demo-model-1', "demo-model-2", "demo-model-3"].includes(product_id) ? 1 : 0, modelDetailsTabs)

    return (
        <div className='action-details-wrapper'>
            <ul>
                {
                    modelDetailsTabs.map((child, index) => (child.slug === 'analytics' && ['demo-model-1', "demo-model-2", "demo-model-3"].includes(product_id)) ? ''
                        : <li key={index}>
                            <button
                                onClick={() => onTabChange(index)}
                                className={`${activeTab === index ? 'active' : null}`}
                            >
                                {child.name}
                            </button>
                        </li>)
                }
            </ul>
            <div className='content'>
                {(activeTab === 0 && !['demo-model-1', "demo-model-2", "demo-model-3"].includes(product_id)) && <div className="products-analytics-wrapper">
                    <select>
                        <option value="0">last 7 days</option>
                        <option value="0">last 14 days</option>
                    </select>
                    <div className="products-analytics-model">
                        <div className="item">
                            <div className="title">
                                <div style={{ background: "#FFF5EB" }} className="icon">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7 3.0625C2.625 3.0625 0.875 7 0.875 7C0.875 7 2.625 10.9375 7 10.9375C11.375 10.9375 13.125 7 13.125 7C13.125 7 11.375 3.0625 7 3.0625Z"
                                            stroke="#E1A829"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                        <path
                                            d="M7 9.1875C8.20812 9.1875 9.1875 8.20812 9.1875 7C9.1875 5.79188 8.20812 4.8125 7 4.8125C5.79188 4.8125 4.8125 5.79188 4.8125 7C4.8125 8.20812 5.79188 9.1875 7 9.1875Z"
                                            fill="#E1A829"
                                            stroke="#F4F5F7"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                </div>
                                <h3>Product views</h3>
                            </div>
                            <div className="data">
                                <div className="count-wrapper">
                                    <div className="total">
                                        <p className='title'>Total</p>
                                        <p className="count">{views}</p>
                                    </div>
                                    <div className="live">
                                        <div className='live-wrapper'>
                                            <div className='live-dot'></div>
                                            <p>Live</p>
                                        </div>
                                        <p className="count">{live_views}</p>
                                    </div>
                                </div>
                                {/* <div className="change">
                                    <div className="percent-wrapper">
                                        <div className="icon">
                                            <svg
                                                width="19"
                                                height="11"
                                                viewBox="0 0 19 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M18.5 1V5.5C18.5 5.95 18.2 6.25 17.75 6.25C17.3 6.25 17 5.95 17 5.5V2.8L11.15 8.65C10.85 8.95 10.4 8.95 10.1 8.65L6.875 5.425L1.775 10.525C1.625 10.675 1.475 10.75 1.25 10.75C1.025 10.75 0.875 10.675 0.725 10.525C0.425 10.225 0.425 9.775 0.725 9.475L6.35 3.85C6.65 3.55 7.1 3.55 7.4 3.85L10.625 7.075L15.95 1.75H13.25C12.8 1.75 12.5 1.45 12.5 1C12.5 0.55 12.8 0.25 13.25 0.25H17.75C17.825 0.25 17.975 0.25 18.05 0.325C18.2 0.4 18.35 0.55 18.425 0.7C18.5 0.775 18.5 0.925 18.5 1Z"
                                                    fill="#27AE60"
                                                ></path>
                                            </svg>
                                        </div>
                                        <p className="percent">45%</p>
                                    </div>
                                    <p className="time">last 7 days</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">
                                <div style={{ background: "#FFE6DE" }} className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M8 5V8" stroke="#E66238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.6 9.5L8 8" stroke="#E66238" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.5127 6.23047H14.0127V3.73047" stroke="#E66238" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.8877 11.8875C11.1187 12.6571 10.1387 13.1814 9.07172 13.394C8.0047 13.6066 6.8986 13.4979 5.89335 13.0818C4.88809 12.6656 4.02885 11.9607 3.4243 11.0561C2.81975 10.1515 2.49707 9.08799 2.49707 8C2.49707 6.91201 2.81975 5.84847 3.4243 4.9439C4.02885 4.03933 4.88809 3.33439 5.89335 2.91824C6.8986 2.50209 8.0047 2.39343 9.07172 2.60601C10.1387 2.81859 11.1187 3.34286 11.8877 4.1125L14.0127 6.23125" stroke="#E66238" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <h3>Time  on product</h3>
                            </div>
                            <div className="data">
                                <p className="count">{engagement_time} seconds</p>
                                {/* <div className="change">
                                    <div className="percent-wrapper">
                                        <div className="icon">
                                            <svg
                                                width="19"
                                                height="11"
                                                viewBox="0 0 19 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M18.5 1V5.5C18.5 5.95 18.2 6.25 17.75 6.25C17.3 6.25 17 5.95 17 5.5V2.8L11.15 8.65C10.85 8.95 10.4 8.95 10.1 8.65L6.875 5.425L1.775 10.525C1.625 10.675 1.475 10.75 1.25 10.75C1.025 10.75 0.875 10.675 0.725 10.525C0.425 10.225 0.425 9.775 0.725 9.475L6.35 3.85C6.65 3.55 7.1 3.55 7.4 3.85L10.625 7.075L15.95 1.75H13.25C12.8 1.75 12.5 1.45 12.5 1C12.5 0.55 12.8 0.25 13.25 0.25H17.75C17.825 0.25 17.975 0.25 18.05 0.325C18.2 0.4 18.35 0.55 18.425 0.7C18.5 0.775 18.5 0.925 18.5 1Z"
                                                    fill="#27AE60"
                                                ></path>
                                            </svg>
                                        </div>
                                        <p className="percent">5%</p>
                                    </div>
                                    <p className="time">last 7 days</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">
                                <div style={{ background: "#E7E8FF" }} className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M10.5001 7V6.25C10.5001 5.91848 10.3684 5.60054 10.134 5.36612C9.8996 5.1317 9.58166 5 9.25013 5C8.91861 5 8.60067 5.1317 8.36625 5.36612C8.13183 5.60054 8.00013 5.91848 8.00013 6.25V2.25C8.00013 1.91848 7.86844 1.60054 7.63402 1.36612C7.3996 1.1317 7.08166 1 6.75013 1C6.41861 1 6.10067 1.1317 5.86625 1.36612C5.63183 1.60054 5.50013 1.91848 5.50013 2.25V9.83125L4.13138 7.45625C3.96562 7.16866 3.69241 6.95869 3.37184 6.87254C3.05127 6.78639 2.7096 6.83111 2.42201 6.99687C2.13442 7.16264 1.92445 7.43585 1.8383 7.75642C1.75215 8.07699 1.79687 8.41866 1.96263 8.70625C4.00013 13 5.23763 14.5 8.00013 14.5C8.65674 14.5 9.30692 14.3707 9.91355 14.1194C10.5202 13.8681 11.0714 13.4998 11.5357 13.0355C12 12.5712 12.3683 12.02 12.6195 11.4134C12.8708 10.8068 13.0001 10.1566 13.0001 9.5V7C13.0001 6.66848 12.8684 6.35054 12.634 6.11612C12.3996 5.8817 12.0817 5.75 11.7501 5.75C11.4186 5.75 11.1007 5.8817 10.8663 6.11612C10.6318 6.35054 10.5001 6.66848 10.5001 7V7Z" stroke="#7159EA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <h3>View in AR clicks</h3>
                            </div>
                            <div className="data">
                                <p className="count">{ar_button_click}</p>
                                {/* <div className="change">
                                    <div className="percent-wrapper">
                                        <div className="icon">
                                            <svg
                                                width="19"
                                                height="11"
                                                viewBox="0 0 19 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M18.5 1V5.5C18.5 5.95 18.2 6.25 17.75 6.25C17.3 6.25 17 5.95 17 5.5V2.8L11.15 8.65C10.85 8.95 10.4 8.95 10.1 8.65L6.875 5.425L1.775 10.525C1.625 10.675 1.475 10.75 1.25 10.75C1.025 10.75 0.875 10.675 0.725 10.525C0.425 10.225 0.425 9.775 0.725 9.475L6.35 3.85C6.65 3.55 7.1 3.55 7.4 3.85L10.625 7.075L15.95 1.75H13.25C12.8 1.75 12.5 1.45 12.5 1C12.5 0.55 12.8 0.25 13.25 0.25H17.75C17.825 0.25 17.975 0.25 18.05 0.325C18.2 0.4 18.35 0.55 18.425 0.7C18.5 0.775 18.5 0.925 18.5 1Z"
                                                    fill="#27AE60"
                                                ></path>
                                            </svg>
                                        </div>
                                        <p className="percent">13%</p>
                                    </div>
                                    <p className="time">last 7 days</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>}
                {activeTab === 1 && <BasicModal iframe={iframe} productLink={productLink} download_url={download_url} />}
                {activeTab === 2 && <div className='details-content-wrapper'>
                    <div className='left-side'>
                        <div className='item-wrapper'>
                            <div className='item'>
                                <div className='title'>License</div>
                                <div className='result'>Standart</div>
                            </div>
                        </div>
                        <div className='item-wrapper'>
                            <div className='item'>
                                <div className='title'>Included 3D formats</div>
                                <div className='result'>USDZ <span>(usdz)</span></div>
                            </div>
                            <div className='item'>
                                <div className='title'></div>
                                <div className='result'>3ds Max <span>(max)</span></div>
                            </div>
                            <div className='item'>
                                <div className='title'></div>
                                <div className='result'>GLTF <span>(gltf)</span></div>
                            </div>
                            <div className='item'>
                                <div className='title'></div>
                                <div className='result'>USDZ <span>(usdz)</span></div>
                            </div>
                        </div>
                    </div>
                    <div className='right-side'>
                        <div className='item-wrapper'>
                            <div className='item'>
                                <div className='title'>Download size</div>
                                <div className='result'>25Mb</div>
                            </div>
                        </div>
                        <div className='item-wrapper'>
                            <div className='item'>
                                <div className='title'>Extra archives size</div>
                                <div className='result'>5Mb</div>
                            </div>
                        </div>
                        <div className='item-wrapper'>
                            <div className='item'>
                                <div className='title'>UV Mapping</div>
                                <div className='result'>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.1094 8.53125L9.29414 13.125L6.89062 10.8281" stroke="#4D9A00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.5 18.375C14.8492 18.375 18.375 14.8492 18.375 10.5C18.375 6.15076 14.8492 2.625 10.5 2.625C6.15076 2.625 2.625 6.15076 2.625 10.5C2.625 14.8492 6.15076 18.375 10.5 18.375Z" stroke="#4D9A00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className='item-wrapper'>
                            <div className='item'>
                                <div className='title'>Textures</div>
                                <div className='result'>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.1094 8.53125L9.29414 13.125L6.89062 10.8281" stroke="#4D9A00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.5 18.375C14.8492 18.375 18.375 14.8492 18.375 10.5C18.375 6.15076 14.8492 2.625 10.5 2.625C6.15076 2.625 2.625 6.15076 2.625 10.5C2.625 14.8492 6.15076 18.375 10.5 18.375Z" stroke="#4D9A00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default ModelDetailsReady

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '6px',
    boxShadow: 24,
    p: 4,
};

const buttonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
    borderRadius: '6px',
    border: '1px solid #E8E9EA',
    padding: '36.5px 18px 37.5px 18px',
    color: '#555E6D',
    fontSize: '20px',
    fontWeight: '500',
    background: 'transparent',
    cursor: 'pointer'
};

const svgStyle = {
    borderRadius: '50%',
    background: 'rgba(245, 248, 255, 0.87)',
    display: 'flex',
    width: '55px',
    height: '55px',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
}

function BasicModal({ iframe, productLink, download_url }) {
    const id = useParams().id
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(null)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const urlRegex = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;

    const [url, setUrl] = useState(productLink || "");
    const [validUrl, setValidUrl] = useState((productLink && true) || false);
    const [submit, setSubmit] = useState((productLink && true) || false)
    const notify = (message) => toast(message);

    const handleChange = (e) => {
        const value = e.target.value;
        setUrl(value);
        const match = urlRegex.test(value);
        setValidUrl(match);
        if (!match) {
            setError('Enter a valid URL')
        } else {
            setError(null)
        }
    };

    const handleSubmitUrl = async (e) => {
        e.preventDefault()

        try {
            await axiosPrivate.patch(`/api/v1/models/${id}/`, {
                product_link: url
            })
            setSubmit(true)
            notify('Product link updated')
        } catch (error) {
            notify("Enter a valid URL")
        }
    }

    const handleEditBtn = (e) => {
        if (submit) {
            setSubmit(false)
        } else {
            handleSubmitUrl(e)
        }
    }

    const handleDownloadModel = () => {
        window.location.href = download_url;
    }

    return (
        <>
            <ToastContainer autoClose={1000} hideProgressBar={true} />
            <div>
                <button style={buttonStyle} onClick={handleOpen}>
                    <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clipPath="url(#clip0_30_8590)">
                            <mask id="mask0_30_8590" maskUnits="userSpaceOnUse" x="0" y="5" width="9" height="14">
                                <path d="M0.755859 5.2002H8.60805V18.8002H0.755859V5.2002Z" fill="white" />
                            </mask>
                            <g mask="url(#mask0_30_8590)">
                                <mask id="mask1_30_8590" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <path d="M22.9629 9.90945H12.2513V14.3505H18.417C17.8422 17.1719 15.4387 18.7916 12.2513 18.7916C8.48917 18.7916 5.45855 15.7612 5.45855 11.9993C5.45855 8.23751 8.48917 5.20713 12.2513 5.20713C13.8711 5.20713 15.3342 5.78188 16.4837 6.72232L19.8278 3.37851C17.79 1.60207 15.1774 0.504883 12.2513 0.504883C5.87655 0.504883 0.755859 5.62513 0.755859 11.9993C0.755859 18.3736 5.87655 23.4938 12.2513 23.4938C17.999 23.4938 23.2242 19.314 23.2242 11.9993C23.2242 11.3201 23.1197 10.5886 22.9629 9.90945Z" fill="white" />
                                </mask>
                                <g mask="url(#mask1_30_8590)">
                                    <path d="M-0.289062 18.7915V5.20703L8.59375 11.9992L-0.289062 18.7915Z" fill="#FBBC05" />
                                </g>
                            </g>
                            <mask id="mask2_30_8590" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="12">
                                <path d="M0.755859 0.496094H23.2242V12.0001H0.755859V0.496094Z" fill="white" />
                            </mask>
                            <g mask="url(#mask2_30_8590)">
                                <mask id="mask3_30_8590" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <path d="M22.9629 9.90945H12.2513V14.3505H18.417C17.8422 17.1719 15.4387 18.7916 12.2513 18.7916C8.48917 18.7916 5.45855 15.7612 5.45855 11.9993C5.45855 8.23751 8.48917 5.20713 12.2513 5.20713C13.8711 5.20713 15.3342 5.78188 16.4837 6.72232L19.8278 3.37851C17.79 1.60207 15.1774 0.504883 12.2513 0.504883C5.87655 0.504883 0.755859 5.62513 0.755859 11.9993C0.755859 18.3736 5.87655 23.4938 12.2513 23.4938C17.999 23.4938 23.2242 19.314 23.2242 11.9993C23.2242 11.3201 23.1197 10.5886 22.9629 9.90945Z" fill="white" />
                                </mask>
                                <g mask="url(#mask3_30_8590)">
                                    <path d="M-0.289062 5.20721L8.59375 11.9994L12.2514 8.81234L24.7918 6.77465V-0.540039H-0.289062V5.20721Z" fill="#EA4335" />
                                </g>
                            </g>
                            <mask id="mask4_30_8590" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                <path d="M0.755859 0.496094H23.2242V23.4934H0.755859V0.496094Z" fill="white" />
                            </mask>
                            <g mask="url(#mask4_30_8590)">
                                <mask id="mask5_30_8590" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <path d="M22.9629 9.90945H12.2513V14.3505H18.417C17.8422 17.1719 15.4387 18.7916 12.2513 18.7916C8.48917 18.7916 5.45855 15.7612 5.45855 11.9993C5.45855 8.23751 8.48917 5.20713 12.2513 5.20713C13.8711 5.20713 15.3342 5.78188 16.4837 6.72232L19.8278 3.37851C17.79 1.60207 15.1774 0.504883 12.2513 0.504883C5.87655 0.504883 0.755859 5.62513 0.755859 11.9993C0.755859 18.3736 5.87655 23.4938 12.2513 23.4938C17.999 23.4938 23.2242 19.314 23.2242 11.9993C23.2242 11.3201 23.1197 10.5886 22.9629 9.90945Z" fill="white" />
                                </mask>
                                <g mask="url(#mask5_30_8590)">
                                    <path d="M-0.289062 18.7916L15.3865 6.77465L19.5144 7.29715L24.7918 -0.540039V24.5389H-0.289062V18.7916Z" fill="#34A853" />
                                </g>
                            </g>
                            <mask id="mask6_30_8590" maskUnits="userSpaceOnUse" x="6" y="5" width="18" height="19">
                                <path d="M6.49585 5.2002H23.224V23.4935H6.49585V5.2002Z" fill="white" />
                            </mask>
                            <g mask="url(#mask6_30_8590)">
                                <mask id="mask7_30_8590" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <path d="M22.9627 9.90945H12.2511V14.3505H18.4168C17.842 17.1719 15.4384 18.7916 12.2511 18.7916C8.48893 18.7916 5.4583 15.7612 5.4583 11.9993C5.4583 8.23751 8.48893 5.20713 12.2511 5.20713C13.8709 5.20713 15.3339 5.78188 16.4834 6.72232L19.8276 3.37851C17.7897 1.60207 15.1772 0.504883 12.2511 0.504883C5.8763 0.504883 0.755615 5.62513 0.755615 11.9993C0.755615 18.3736 5.8763 23.4938 12.2511 23.4938C17.9987 23.4938 23.2239 19.314 23.2239 11.9993C23.2239 11.3201 23.1194 10.5886 22.9627 9.90945Z" fill="white" />
                                </mask>
                                <g mask="url(#mask7_30_8590)">
                                    <path d="M24.7915 24.5387L8.59348 11.9992L6.50342 10.4318L24.7915 5.20703V24.5387Z" fill="#4285F4" />
                                </g>
                            </g>
                        </g>
                        <defs>
                            <clipPath id="clip0_30_8590">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <p>Google swirl</p>
                </button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="model-ads-wrapper">
                            <div className="head">
                                <p className='title'>Model text here</p>
                            </div>
                            <div className="model">
                                <div className="iframe-wrapper">
                                    <iframe src={iframe} frameBorder="0" allow="fullscreen" title='iframe'></iframe>
                                </div>
                            </div>
                            <form onSubmit={(e) => { handleSubmitUrl(e) }} className="ads-describtion">
                                <input onChange={handleChange} type="text" placeholder='Add product link here...' value={url} readOnly={submit} />
                                <button type='button' onClick={(e) => { handleEditBtn(e) }}>
                                    {
                                        !submit
                                            ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M3.125 10L16.875 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.25 15.625L16.875 10L11.25 4.375" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M7.5 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6919C3.19085 16.5747 3.125 16.4157 3.125 16.2499V12.7577C3.12472 12.6766 3.14044 12.5962 3.17128 12.5211C3.20211 12.446 3.24745 12.3778 3.30469 12.3202L12.6797 2.94524C12.7378 2.88619 12.8072 2.83929 12.8836 2.80728C12.9601 2.77527 13.0421 2.75879 13.125 2.75879C13.2079 2.75879 13.2899 2.77527 13.3664 2.80728C13.4428 2.83929 13.5122 2.88619 13.5703 2.94524L17.0547 6.42962C17.1137 6.48777 17.1606 6.5571 17.1927 6.63355C17.2247 6.71 17.2411 6.79205 17.2411 6.87493C17.2411 6.95781 17.2247 7.03987 17.1927 7.11632C17.1606 7.19277 17.1137 7.26209 17.0547 7.32024L7.5 16.8749Z" stroke="#F4F5F7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16.875 16.875H7.5" stroke="#F4F5F7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.625 5L15 9.375" stroke="#F4F5F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                    }

                                </button>
                            </form>
                            {error && <p className='error-text'>Enter a valid URL</p>}
                            <button onClick={handleDownloadModel} className={`${(!url || !validUrl) && 'disabled-btn'} download-ads-btn`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                                    <path d="M7.05469 9.52344L10.5 12.9688L13.9453 9.52344" stroke={(validUrl) ? '#3578EA' : '#D1D1D1'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10.5 3.78125V12.9688" stroke={(validUrl) ? '#3578EA' : '#D1D1D1'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.7188 12.9688V17.5625C17.7188 17.7365 17.6496 17.9035 17.5265 18.0265C17.4035 18.1496 17.2365 18.2188 17.0625 18.2188H3.9375C3.76345 18.2188 3.59653 18.1496 3.47346 18.0265C3.35039 17.9035 3.28125 17.7365 3.28125 17.5625V12.9688" stroke={(validUrl) ? '#3578EA' : '#D1D1D1'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <p>Download</p>
                            </button>
                        </div>
                    </Box>
                </Modal>
            </div >
        </>
    );
}