import { createAsyncThunk, current } from '@reduxjs/toolkit'
import { axiosPrivate } from '../../api/api';

const demoModel = [
    {
        id: "demo-model-1",
        model: {
            "id": "demo-model-1",
            "name": "Hottub Dream",
            "sku": "32984jd",
            "image": "https://dev.api.imajin.ai/media/model_images/poster_19.png",
            "file": "https://dev.api.imajin.ai/media/ar_models/dre2600-2cl_1.glb",
            "usdz": null,
            "script": "<div id=\"imajin-model\" data-model-id=\"6782fe17-1d55-49d9-8552-d2de0553bb2d\"></div>\n<script src=\"https://dev.viewer.imajin.ai/scripts/embed.js\"></script>",
            "iframe": "<iframe src=https://dev.app.imajin.ai/model/viewer/6782fe17-1d55-49d9-8552-d2de0553bb2d style=\"overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100vh;width:100%;z-index: 1;position: relative;\" frameborder=\"0\" allow=\"fullscreen\"></iframe>",
            "google_swirl_iframe": "https://dev.api.imajin.ai/models/google-swirl/6782fe17-1d55-49d9-8552-d2de0553bb2d",
            "google_swirl_download_link": "https://dev.api.imajin.ai/media/ar_models/6782fe17-1d55-49d9-8552-d2de0553bb2d/google_swirl/google-swirl-6782fe17-1d55-49d9-8552-d2de0553bb2d.zip",
            "product_link": null,
            "is_autoload": true,
            "is_autorotate": true,
            "is_animate": true,
            "environment": "none",
            "background_color": "#FFFFFF",
            "variants": [
                {
                    "name": "Cabin",
                    "options": [
                        {
                            "id": 605,
                            "name": "Anthrazith",
                            "is_active": false
                        },
                        {
                            "id": 606,
                            "name": "Brown",
                            "is_active": false
                        },
                        {
                            "id": 607,
                            "name": "Grey",
                            "is_active": true
                        }
                    ]
                },
                {
                    "name": "Water",
                    "options": [
                        {
                            "id": 608,
                            "name": "Off",
                            "is_active": true
                        },
                        {
                            "id": 609,
                            "name": "On",
                            "is_active": true
                        }
                    ]
                }
            ],
            "images_360": null,
            "annotations": [
                {
                    "id": "20115959-7c0e-4a26-a2ae-1f2574c134f9",
                    "text": "Wow2",
                    "data_position": {
                        "normal": {
                            "x": 0.07870175499905216,
                            "y": 0.9964917232139305,
                            "z": 0.02846540577965885
                        },
                        "position": {
                            "x": 0.8710583258374835,
                            "y": 0.7514437070720317,
                            "z": 0.1606726068359414
                        }
                    }
                }
            ]
        },
    },
    {
        id: "demo-model-2",
        model: {
            "id": "demo-model-2",
            "name": "Delonghi La Specialista",
            "sku": "i24bd83",
            "image": "https://dev.api.imajin.ai/media/model_images/poster_17.png",
            "file": "https://dev.api.imajin.ai/media/ar_models/coffee3.glb",
            "usdz": null,
            "script": "<div id=\"imajin-model\" data-model-id=\"aef689ee-6e94-4a62-8c31-5b84a65eab90\"></div>\n<script src=\"https://dev.viewer.imajin.ai/scripts/embed.js\"></script>",
            "iframe": "<iframe src=https://dev.app.imajin.ai/model/viewer/aef689ee-6e94-4a62-8c31-5b84a65eab90 style=\"overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100vh;width:100%;z-index: 1;position: relative;\" frameborder=\"0\" allow=\"fullscreen\"></iframe>",
            "google_swirl_iframe": "https://dev.api.imajin.ai/models/google-swirl/aef689ee-6e94-4a62-8c31-5b84a65eab90",
            "google_swirl_download_link": "https://dev.api.imajin.ai/media/ar_models/aef689ee-6e94-4a62-8c31-5b84a65eab90/google_swirl/google-swirl-aef689ee-6e94-4a62-8c31-5b84a65eab90.zip",
            "product_link": null,
            "is_autoload": true,
            "is_autorotate": true,
            "is_animate": false,
            "environment": "none",
            "background_color": "rgba(255, 255, 255, 0)",
            "variants": [],
            "images_360": null,
            "annotations": [
                {
                    "id": "afd209bc-88c9-4626-b007-7f2d9e8b853f",
                    "text": "Super Press",
                    "data_position": {
                        "normal": {
                            "x": 0.6268513039884357,
                            "y": -0.1529767129031779,
                            "z": 0.76397353880703
                        },
                        "position": {
                            "x": 3.291351292751865,
                            "y": -0.012403800994512451,
                            "z": -3.119970133509603
                        }
                    }
                }
            ]
        }
    },
    {
        id: "demo-model-3",
        model: {
            "id": "demo-model-3",
            "name": "Frost X",
            "sku": "904308090909",
            "image": "https://dev.api.imajin.ai/media/ar_models/ea513565-f95f-49f5-9d9e-67886b36dc6f/image/poster_12.png",
            "file": "https://dev.api.imajin.ai/media/ar_models/ea513565-f95f-49f5-9d9e-67886b36dc6f/file/Frost_2.glb",
            "usdz": "https://dev.api.imajin.ai/media/ar_models/ea513565-f95f-49f5-9d9e-67886b36dc6f/file/Frost_2.usdz",
            "script": "<div id=\"imajin-model\" data-model-id=\"ea513565-f95f-49f5-9d9e-67886b36dc6f\"></div>\n<script src=\"https://dev.viewer.imajin.ai/scripts/embed.js\"></script>",
            "iframe": "<iframe src=https://dev.app.imajin.ai/model/viewer/ea513565-f95f-49f5-9d9e-67886b36dc6f style=\"overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100vh;width:100%;z-index: 1;position: relative;\" frameborder=\"0\" allow=\"fullscreen\"></iframe>",
            "google_swirl_iframe": "https://dev.api.imajin.ai/models/google-swirl/ea513565-f95f-49f5-9d9e-67886b36dc6f",
            "google_swirl_download_link": "https://dev.api.imajin.ai/media/ar_models/ea513565-f95f-49f5-9d9e-67886b36dc6f/google_swirl/google-swirl-ea513565-f95f-49f5-9d9e-67886b36dc6f.zip",
            "product_link": "https://embawood.az/yumshaq-mebeller/kuncmodulu/frost-x-soft-kunc-divan",
            "is_autoload": true,
            "is_autorotate": false,
            "is_animate": true,
            "environment": "none",
            "background_color": "rgba(255, 255, 255, 0)",
            "variants": [],
            "images_360": null,
            "annotations": [
                {
                    "id": "bdc8ab5f-b2bc-47d4-9238-9bd82d446dda",
                    "text": "XBL hörmə parça. Tez quruyan, sürtünməyə və günəş şüalarına qarşı davamlıdır.",
                    "data_position": {
                        "normal": {
                            "x": 6.617444900424222e-24,
                            "y": 0.9999999999999541,
                            "z": 3.029462793290626e-07
                        },
                        "position": {
                            "x": -0.045900858131068256,
                            "y": 0.39117437396280763,
                            "z": -0.13146323661977233
                        }
                    }
                },
                {
                    "id": "68042733-e730-4753-8fdf-df4fc6918fe3",
                    "text": "Mexanizma döşəyi.",
                    "data_position": {
                        "normal": {
                            "x": 6.938893903907228e-18,
                            "y": 0.47319780031442965,
                            "z": 0.8809562087740714
                        },
                        "position": {
                            "x": 0.7646548185278479,
                            "y": 0.1822912956827903,
                            "z": 0.09798919837916475
                        }
                    }
                }
            ]
        }
    }
]

function isModelInArray(modelId) {
    return demoModel.find(item => item.id === modelId);
}

const updateModelFields = async (data, id) => {
    try {
        await axiosPrivate.patch(`/api/v1/models/${id}/`, data)
    } catch (error) {
        console.log(error);
    }
}

const getModelViewerData = createAsyncThunk('modelViewer/ferchModel', async (id, { rejectWithValue }) => {
    if (isModelInArray(id)) {
        console.log(isModelInArray(id).model);
        return isModelInArray(id).model
    } else {
        try {
            const result = await axiosPrivate.get(`/api/v1/models/${id}/`)
            return result.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
})

const changeBgColor = (state, { payload }) => {
    console.log(payload);
    state.data.background_color = payload.color
    // if (payload.change) {
    //     updateModelFields({ "background_color": payload.color, }, payload.id)
    // }
}

const handleAutoRotate = (state, { payload }) => {
    state.data.is_autorotate = payload.is_autorotate
    updateModelFields({ "is_autorotate": payload.is_autorotate, }, payload.id)
}

const handleAutoLoad = (state, { payload }) => {
    updateModelFields({ "is_autoload": payload.is_autoload, }, payload.id)
}

const changeAddNewStatus = (state, { payload }) => {
    state.modelAnnations.addNew = payload
}

const changeShowInputStatus = (state, { payload }) => {
    state.modelAnnations.showInput = payload
}

const changeAnnotations = (state, { payload }) => {
    state.data.annotations.push(payload);
}

const changeAnnotationText = (state, { payload }) => {
    const { annotations } = current(state).data;

    const newAnnotationList = annotations.map(annotation => {
        if (annotation.id === payload.id) {
            return { ...annotation, text: payload.text };
        }
        return annotation;
    });

    const updateAnnotationTextServer = async (id, message) => {
        try {
            await axiosPrivate.patch(`/api/v1/models/annotation/${id}/`, message)
        } catch (error) {
            console.log(error);
        }
    }

    updateAnnotationTextServer(payload.id, { text: payload.text })
    state.data.annotations = newAnnotationList
}

const deleteAnnotationItem = (state, { payload }) => {
    const { annotations } = current(state).data;

    const newAnnotationList = annotations.filter(annotation => {
        if (annotation.id !== payload) {
            return annotation
        }
        return false;
    });

    const deleteBack = async (id) => {
        await axiosPrivate.delete(`/api/v1/models/annotation/${id}/`)
    }

    deleteBack(payload)
    state.data.annotations = newAnnotationList
}

const deleteAnnotationLastItem = (state) => {
    const { annotations } = current(state).data;
    const newArr = [...annotations];
    newArr.pop()
    state.data.annotations = newArr
}

const updateVariantItem = (state, { payload: { name, id, status } }) => {
    const { variants } = state.data;
    const newVariants = variants.map(variant => {
        if (variant.name !== name) return variant;
        const options = variant.options.map(option => {
            if (option.id !== id) return option;
            return { ...option, is_active: status };
        });
        return { ...variant, options };
    });

    const updateVariantOptionStatus = async (id, is_active) => {
        try {
            await axiosPrivate.patch(`/api/v1/models/variant/${id}/`, {
                "is_active": is_active
            })
        } catch (error) {
            console.log(error);
        }
    }

    updateVariantOptionStatus(id, status)

    return { ...state, data: { ...state.data, variants: newVariants } };
};


const changeEnvironment = (state, { payload }) => {
    state.data.environment = payload.environment;
    updateModelFields({ "environment": payload.environment, }, payload.id)
}

export {
    getModelViewerData,
    changeBgColor,
    handleAutoRotate,
    handleAutoLoad,
    changeAddNewStatus,
    changeShowInputStatus,
    changeAnnotations,
    changeAnnotationText,
    deleteAnnotationItem,
    deleteAnnotationLastItem,
    updateVariantItem,
    changeEnvironment
}