import React, { useState } from 'react'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import "./HomeGrafic.scss"

const data = [
    {
        name: "20240125",
        uv: "36"
    },
    {
        name: "20240126",
        uv: "20"
    },
    {
        name: "20240127",
        uv: "2"
    },
    {
        name: "20240128",
        uv: "7"
    },
    {
        name: "20240129",
        uv: "11"
    },
    {
        name: "20240130",
        uv: "14"
    },
    {
        name: "20240131",
        uv: "5"
    },
    {
        name: "20240201",
        uv: "5"
    },
    {
        name: "20240202",
        uv: "8"
    },
    {
        name: "20240203",
        uv: "2"
    },
    {
        name: "20240204",
        uv: "2"
    },
    {
        name: "20240205",
        uv: "8"
    },
    {
        name: "20240206",
        uv: "9"
    },
    {
        name: "20240208",
        uv: "2"
    },
    {
        name: "20240209",
        uv: "2"
    },
    {
        name: "20240210",
        uv: "1"
    },
    {
        name: "20240211",
        uv: "3"
    },
    {
        name: "20240212",
        uv: "6"
    },
    {
        name: "20240213",
        uv: "6"
    },
    {
        name: "20240214",
        uv: "3"
    },
    {
        name: "20240215",
        uv: "1"
    },
    {
        name: "20240216",
        uv: "2"
    },
    {
        name: "20240217",
        uv: "4"
    },
    {
        name: "20240218",
        uv: "1"
    },
    {
        name: "20240219",
        uv: "62"
    },
    {
        name: "20240220",
        uv: "4"
    },
    {
        name: "20240221",
        uv: "7"
    },
    {
        name: "20240222",
        uv: "3"
    },
    {
        name: "20240223",
        uv: "5"
    },
    {
        name: "20240224",
        uv: "1"
    },
    {
        name: "20240226",
        uv: "3"
    },
    {
        name: "20240227",
        uv: "2"
    },
    {
        name: "20240228",
        uv: "3"
    },
    {
        name: "20240301",
        uv: "2"
    },
    {
        name: "20240302",
        uv: "1"
    },
    {
        name: "20240303",
        uv: "2"
    },
    {
        name: "20240304",
        uv: "2"
    },
    {
        name: "20240305",
        uv: "9"
    },
    {
        name: "20240306",
        uv: "6"
    },
    {
        name: "20240307",
        uv: "6"
    },
    {
        name: "20240309",
        uv: "3"
    },
    {
        name: "20240311",
        uv: "3"
    },
    {
        name: "20240312",
        uv: "3"
    },
    {
        name: "20240313",
        uv: "3"
    },
    {
        name: "20240314",
        uv: "4"
    },
    {
        name: "20240315",
        uv: "5"
    },
    {
        name: "20240316",
        uv: "1"
    },
    {
        name: "20240317",
        uv: "2"
    },
    {
        name: "20240318",
        uv: "1"
    },
    {
        name: "20240319",
        uv: "6"
    },
    {
        name: "20240320",
        uv: "4"
    },
    {
        name: "20240321",
        uv: "13"
    },
    {
        name: "20240322",
        uv: "2"
    },
    {
        name: "20240323",
        uv: "2"
    },
    {
        name: "20240324",
        uv: "1"
    },
    {
        name: "20240326",
        uv: "2"
    },
    {
        name: "20240327",
        uv: "2"
    },
    {
        name: "20240328",
        uv: "6"
    },
    {
        name: "20240331",
        uv: "1"
    },
    {
        name: "20240401",
        uv: "5"
    },
    {
        name: "20240402",
        uv: "6"
    },
    {
        name: "20240403",
        uv: "4"
    },
    {
        name: "20240404",
        uv: "5"
    },
    {
        name: "20240405",
        uv: "1"
    },
    {
        name: "20240408",
        uv: "4"
    },
    {
        name: "20240409",
        uv: "3"
    },
    {
        name: "20240411",
        uv: "3"
    },
    {
        name: "20240412",
        uv: "4"
    },
    {
        name: "20240414",
        uv: "2"
    },
    {
        name: "20240416",
        uv: "4"
    },
    {
        name: "20240417",
        uv: "5"
    },
    {
        name: "20240418",
        uv: "4"
    },
    {
        name: "20240419",
        uv: "5"
    },
    {
        name: "20240420",
        uv: "2"
    },
    {
        name: "20240421",
        uv: "2"
    },
    {
        name: "20240422",
        uv: "4"
    },
    {
        name: "20240423",
        uv: "6"
    }
];

const HomeGrafic = () => {
    const tabs = ['Last 24 hour', 'Last week', 'Last month', 'Last year'];
    const [activeTab, setActiveTab] = useState(tabs[0]);

    return (
        <div className="highlights-grafic d-m-none">
            <div className='header'>
                <select>
                    <option value="0">Time on product</option>
                    <option value="1">Product views</option>
                    <option value="2">View in AR clicks</option>
                </select>
                <ul>
                    {tabs.map((tab) => (
                        <li
                            key={tab}
                            className={activeTab === tab ? 'active' : ''}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </li>
                    ))}
                </ul>
            </div>
            <div className='body' style={{ width: "100%", height: '272px' }}>
                <ResponsiveContainer>
                    <AreaChart
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5.77%" stopColor="#2b6cee" stopOpacity={0.2} />
                                <stop offset="95.67%" stopColor="#3577ea" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <Area type="monotone" dataKey="uv" stroke="#3578EA" fill="url(#colorUv)" />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default HomeGrafic